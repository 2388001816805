import React from 'react'
import { css, keyframes } from '@emotion/react'

import color from '@/constants/color'
import { mq } from '@/components/media/media'
import { RebootusHead } from '@/components/rebootus/rebootusHead'
import { StyledLink } from '@/components/link'
import { HeaderNav } from '@/components/section/header/header-nav'
import { headerNavList } from '@/constants/header/rebootus'
import { FooterBottom } from '@/components/section/footer/footer-bottom'
import { footerBottomList } from '@/constants/footer/rebootus'
import { cssRebootusButton } from '@/components/rebootus/linkStyles'
import { NewsList } from '@/components/common/news'
import { cssNewsLink } from '@/constants/linkStyles'
import { tempNewsList } from '@/constants/news/rebootus'
import { CompanyDataList } from '@/components/common/data-list'
import arrow from '@/images/arrow212121.svg'
import header_rebootus_logo from '@/images/rebootus/logo-corporate.svg'
import rebootus_logo from '@/images/rebootus/logo.svg'
import more_btn from '@/images/rebootus/more_btn.svg'

const definitionList = [
  {
    term: '会社名',
    description: '株式会社Rebootus(Reboo+)'
  },
  {
    term: '代表取締役 CEO',
    description: '太田 優輝'
  },
  {
    term: '所在地',
    description:
      '〒150-6019 東京都渋谷区恵比寿4丁目20番3号　恵比寿ガーデンプレイスタワー19F'
  },
  {
    term: '株主構成',
    description: '株式会社Relicホールディングス 100%'
  },
  {
    term: '事業内容',
    description:
      '事業/プロダクトの評価や撤退/ピボット、再生・再成長の総合支援事業、M&Aや事業の譲渡/流通に関する総合支援事業、及びそれに付帯する業務'
  },
  {
    term: '設立',
    description: '2022年4月'
  }
]

export default function Rebootus(): JSX.Element {
  return (
    <>
      <RebootusHead title="株式会社Rebootus(Reboo+)" url="group/rebootus" />
      <HeaderNav
        css={cssHeaderNav}
        url="/group/rebootus"
        imgSrc={header_rebootus_logo}
        alt="Rebootus"
        navList={headerNavList}
        gradientColor={[color.yellow1, color.green1]}
        btnFontStyleCss={cssBtnFontStyle}
        arrowFillColor={color.black2}
        headerLogoCss={cssHeaderLogoSize}
        breakpoint={768}
      />
      <main css={cssMain}>
        <section css={cssKVWrapper}>
          <span css={cssScrollIndicator}>SCROLL</span>
          <div css={[cssCenter, cssKV]}>
            <div css={cssLead}>
              <span css={cssAnimation1}>
                <img css={cssLogo} src={rebootus_logo} alt="Rebootus" />
              </span>
              <span css={cssAnimation2}>
                事業の再起動＋αの循環で、
                <br className="under-xs" />
                再挑戦を民主化する。
              </span>
            </div>
          </div>
        </section>
        <section id="news" css={cssNewsLayout}>
          <h2 css={[cssH2, cssNewsHeading]}>
            <span>NEWS</span>
            <span>お知らせ</span>
          </h2>
          <NewsList css={cssNewsList} newsList={tempNewsList} />
          <StyledLink
            to="/group/rebootus/news"
            src={more_btn}
            theme={cssNewsLink}
          >
            More detail
          </StyledLink>
        </section>
        <div id="company" css={wrapGrayStart}>
          <div css={wrapGray}>
            <section>
              <div css={cssCompanyLayoutInr}>
                <h2 css={cssH2}>
                  <span>ABOUT US</span>
                  <br />
                  <span>会社情報</span>
                </h2>
                <CompanyDataList definitionList={definitionList} css={cssMt} />
              </div>
            </section>

            <section css={cssContactLayout}>
              <h2 css={cssH2}>
                <span>CONTACT</span>
                <br />
                <span>お問い合わせ</span>
              </h2>
              <div css={cssContactBG}></div>
              <p css={[cssParagraph, cssContactParagraph]}>
                株式会社Rebootus(Reboo+)に関するお問い合わせはこちらからご連絡ください。
              </p>
              <div css={cssCenter}>
                <StyledLink
                  href="https://rebootus.co.jp/form"
                  theme={cssRebootusButton}
                  src={arrow}
                >
                  お問い合わせはこちら
                </StyledLink>
              </div>
            </section>
          </div>
        </div>
      </main>
      <FooterBottom footerBottomList={footerBottomList} />
    </>
  )
}

const cssHeaderNav = css`
  padding: 15px 16px;
  font-family: 'Jost', 'Noto Sans JP', 'Yu Gothic Medium', '游ゴシック Medium',
    YuGothic, '游ゴシック体', 'ヒラギノ角ゴ Pro W3', 'メイリオ',
    'Hiragino Kaku Gothic Pro', Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic',
    sans-serif;
  box-shadow: 0 3px 6px ${color.gray1};
  ${mq.pc} {
    box-shadow: 0 0 16px ${color.gray1};
  }
`

const cssHeaderLogoSize = css`
  width: 90px;
  ${mq.mid} {
    width: 195px;
  }
`

const cssBtnFontStyle = css`
  font-weight: 600;
  color: ${color.black2};
`

const cssMain = css`
  background-color: ${color.gray2};
`

const cssMt = css`
  margin-top: 38px;
  ${mq.pc} {
    margin-top: 80px;
  }
`

// キービジュアル
const cssKV = css`
  position: relative;
  margin: 0 auto;
`

const cssLogo = css`
  width: 240px;
  ${mq.pc} {
    width: 300px;
  }
  img {
    height: 100%;
  }
`

const cssKVWrapper = css`
  position: relative;
  display: flex;
  height: 300px;
  padding-top: 42px;
  ${mq.pc} {
    height: 595px;
    padding-top: 76px;
  }
  ${mq.large} {
    background-size: cover;
  }
`

const cssLead = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 15px;
  > span {
    text-align: center;
    &:last-child {
      margin-top: 28px;
      font-size: 20px;
      ${mq.pc} {
        margin-top: 56px;
        font-size: 30px;
      }
      span {
        font-size: calc(4vw + 2.5px);
        letter-spacing: calc(0.4vw + 0.25px);
        ${mq.pc} {
          font-family: 'Noto Sans JP', 'Yu Gothic Medium', '游ゴシック Medium',
            YuGothic, '游ゴシック体', 'ヒラギノ角ゴ Pro W3', 'メイリオ',
            'Hiragino Kaku Gothic Pro', Meiryo, Osaka, 'ＭＳ Ｐゴシック',
            'MS PGothic', sans-serif;
          font-size: calc((100vw - 768px) / 40 + 30px);
          letter-spacing: calc((100vw - 768px) / 200 + 6px);
        }
      }
    }
    &:first-child {
      font-size: calc(4.3vw + 3px);
      color: ${color.blue3};
      letter-spacing: calc(0.43vw + 0.3px);
      ${mq.pc} {
        font-size: calc((100vw - 768px) / 50 + 30px);
        font-weight: 500;
        letter-spacing: calc((100vw - 768px) / 500 + 3px);
      }
      span {
        position: relative;
        font-size: calc(4.3vw + 3px);
        letter-spacing: calc(0.43vw + 0.3px);
        ${mq.pc} {
          font-size: calc((100vw - 768px) / 50 + 30px);
          font-weight: 500;
          letter-spacing: calc((100vw - 768px) / 500 + 3px);
        }
        &::before {
          position: absolute;
          top: 49%;
          right: calc((1px + 1.1vw) * -1);
          bottom: auto;
          left: auto;
          width: calc(0.8px + 0.9vw);
          height: calc(0.4px + 0.45vw);
          content: '';
          background-color: ${color.blue3};
          transform: translateY(-50%);
          ${mq.pc} {
            right: calc((4px + 0.6vw) * -1);
            width: calc(4.5px + 0.4vw);
            height: calc(2.2px + 0.2vw);
          }
        }
      }
    }
  }
`

const leadAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const cssAnimation1 = css`
  animation: ${leadAnimation} 1s ease 0.5s 1 normal backwards;
`

const cssAnimation2 = css`
  animation: ${leadAnimation} 2s ease 1s 1 normal backwards;
`

const cssScrollIndicator = css`
  display: none;
  ${mq.pc} {
    position: absolute;
    top: 55%;
    right: auto;
    bottom: auto;
    left: 2px;
    display: block;
    font-size: 12px;
    letter-spacing: 1.2px;
    transform: rotate(90deg);
    &::before {
      position: absolute;
      top: 50%;
      right: auto;
      bottom: auto;
      left: calc(100% + 6px);
      width: 52px;
      height: 1px;
      content: '';
      background-color: ${color.black1};
      transform: translateY(-50%);
    }
    &::after {
      position: absolute;
      top: 9px;
      right: auto;
      bottom: auto;
      left: calc(100% + 58px);
      width: 8.5px;
      height: 1px;
      content: '';
      background-color: ${color.black1};
      transform: rotate(-135deg);
      transform-origin: 0 0;
    }
  }
`

const cssH2 = css`
  position: relative;
  z-index: 1;
  color: ${color.black2};
  text-align: center;
  span {
    font-family: 'Lato', sans-serif;
    font-size: 28px;
    font-weight: 700;
    ${mq.pc} {
      font-size: 54px;
      letter-spacing: 0.054em;
    }

    &:last-child {
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 2.5px;
      ${mq.pc} {
        font-size: 28px;
        letter-spacing: 2.8px;
      }
    }
  }
`
// セクションタイトル

const cssNewsLayout = css`
  position: relative;
  max-width: 1080px;
  padding: 57px 16px 0 16px;
  margin: 0 auto;
  ${mq.pc} {
    padding: 101px 16px 0;
    &::after {
      position: absolute;
      top: auto;
      bottom: 11px;
      width: calc(100% - 182px);
      height: 1px;
      content: '';
      background-color: ${color.gray1};
    }
  }
`

const cssNewsHeading = css`
  position: relative;
  margin: 16px auto;
  text-align: left;
  ${mq.pc} {
    display: flex;
    align-items: center;
    font-weight: 700;
  }
  span {
    position: relative;
    z-index: 1;
    background-color: ${color.gray2};
    &:last-child {
      padding: 0 12px;
      font-weight: 400;
      ${mq.pc} {
        padding: 0 16px 0 22px;
        font-weight: 700;
      }
    }
  }
  &::before {
    position: absolute;
    top: 50%;
    bottom: auto;
    width: 100%;
    height: 1px;
    content: '';
    background-color: ${color.gray1};
  }
`

const cssNewsList = css`
  li {
    padding: 10px 0;
    margin-top: 0;
    ${mq.pc} {
      padding-bottom: 24px;
      margin-top: 0;
      border-bottom: none;
    }
  }

  p {
    ${mq.pc} {
      font-size: 18px;
    }
  }
`

//  ニュースここまで

const wrapGrayStart = css`
  padding-top: 60px;
  ${mq.pc} {
    padding-top: 130px;
  }
`

const cssCompanyLayoutInr = css`
  position: relative;
  padding: 0 16px;

  h2 {
    position: relative;
    top: -20px;

    ${mq.pc} {
      top: -40px;
    }
  }
`

// 会社概要ここまで

const cssContactLayout = css`
  position: relative;
  padding: 60px 16px;
  ${mq.pc} {
    padding: 120px 16px;
  }
`

const cssContactBG = css`
  position: absolute;
  top: 78px;
  right: auto;
  bottom: auto;
  left: 0;
  width: 100%;
  height: 240px;
  background: ${color.white1};
  background-repeat: no-repeat;
  background-size: cover;
  transform: scale(-1, 1);
  ${mq.pc} {
    top: 165px;
    height: 333px;
    background-size: auto;
  }
  ${mq.large} {
    background-size: cover;
  }
  img {
    height: 100%;
  }
`

const cssParagraph = css`
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5;
  color: ${color.black1};
  letter-spacing: 1px;
  ${mq.pc} {
    text-align: center;
  }
`

const cssContactParagraph = css`
  position: relative;
  margin: 16px auto;
  ${mq.pc} {
    margin: 20px auto 30px;
  }
`

const cssCenter = css`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
// お問合せここまで

//追加
const wrapGray = css`
  background: ${color.white4};
`
